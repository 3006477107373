import React, { useMemo } from 'react';
import { Routes, Route } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'
import { faCashRegister, faScrewdriverWrench, faTruck, faTruckClock, faTruckRampBox, faUsers } from '@fortawesome/pro-solid-svg-icons'
// import logoImg from './logo.png'

import WFCustomerListPage from './workflow/CustomerReqList'
// import CustomerReqCreatePage from './workflow/CustomerReqCreate'
import WFModImpegnoListPage from './workflow/ModImpegnoList'
import WFModImpegnoRowListPage from './workflow/ModImpegnoRowList'
import WFModImpegnoRowEditPage from './workflow/ModImpegnoRowEdit'
import WFComponentListPage from './workflow/ComponentReqList'
import WFComponentEditPage from './workflow/ComponentReqEdit'
import WFSupplierListPage from './workflow/SupplierList'
import WFSupplierEditPage from './workflow/SupplierEdit'
import ConfigCustomerListPage from './config/CustomerList'
import ConfigCustomerEditPage from './config/CustomerEdit'
import ConfigSupplierListPage from './config/SupplierList'
import ConfigSupplierEditPage from './config/SupplierEdit'

const Router = () => (
	<Routes>
		<Route path="/workflow/customer/list" element={<WFCustomerListPage />} />
		<Route path="/workflow/customer/create" element={<WFComponentEditPage />} />

		<Route path="/workflow/component/list" element={<WFComponentListPage />} />
		<Route path="/workflow/component/edit/:_id" element={<WFComponentEditPage />} />
		<Route path="/workflow/component/customer-edit/:customerRequest/:_id" element={<WFComponentEditPage />} />
		<Route path="/workflow/component/customer-edit/:customerRequest" element={<WFComponentEditPage />} />
		<Route path="/workflow/component/customer-list/:customerRequest" element={<WFComponentListPage />} />

		<Route path="/workflow/supplier/list" element={<WFSupplierListPage />} />
		<Route path="/workflow/supplier/component-list/:componentRequest" element={<WFSupplierListPage />} />
		<Route path="/workflow/supplier/customer-list/:customerRequest/:componentRequest" element={<WFSupplierListPage />} />
		<Route path="/workflow/supplier/edit/:_id" element={<WFSupplierEditPage />} />
		<Route path="/workflow/supplier/component-edit/:componentRequest/:_id" element={<WFSupplierEditPage />} />
		<Route path="/workflow/supplier/component-edit/:componentRequest" element={<WFSupplierEditPage />} />
		<Route path="/workflow/supplier/customer-edit/:customerRequest/:componentRequest/:_id" element={<WFSupplierEditPage />} />
		<Route path="/workflow/supplier/customer-edit/:customerRequest/:componentRequest" element={<WFSupplierEditPage />} />
		
		<Route path="/workflow/commitment-form/list" element={<WFModImpegnoListPage />} />
		<Route path="/workflow/commitment-form/row/:reqModuleId/list" element={<WFModImpegnoRowListPage />} />
		<Route path="/workflow/commitment-form/row/:reqModuleId/edit/:_id" element={<WFModImpegnoRowEditPage />} />
		<Route path="/workflow/commitment-form/row/:reqModuleId/edit" element={<WFModImpegnoRowEditPage />} />
		
		<Route path="/config/customer/edit/:_id" element={<ConfigCustomerEditPage />} />
		<Route path="/config/customer/edit" element={<ConfigCustomerEditPage />} />
		<Route path="/config/customer/list" element={<ConfigCustomerListPage />} />
		<Route path="/config/supplier/edit/:_id" element={<ConfigSupplierEditPage />} />
		<Route path="/config/supplier/edit" element={<ConfigSupplierEditPage />} />
		<Route path="/config/supplier/list" element={<ConfigSupplierListPage />} />

		<Route path="/" element={<Blank />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="Dubbini S.p.A." />
)

const useMenu = (api) => useMemo(() => {
	const menu = []

	const menuWorkflows = []
	if(api.auth.chk('vendite'))
		menuWorkflows.push({ label:"Richieste cliente", icon:faCashRegister, linkTo:"/workflow/customer/list" })
	if(api.auth.chkAny([ 'vendite', 'acquisti' ]))
		menuWorkflows.push({ label:"Richieste componenti", icon:faScrewdriverWrench, linkTo:"/workflow/component/list" })
	if(api.auth.chkAny([ 'vendite', 'acquisti' ]))
		menuWorkflows.push({ label:"Risposte fornitori", icon:faTruckClock, linkTo:"/workflow/supplier/list" })
	if(api.auth.chkAny([ 'vendite', 'acquisti', 'customer-service' ]))
		menuWorkflows.push({ label:"Moduli impegno", icon:faTruckRampBox, linkTo:"/workflow/commitment-form/list" })
	if(menuWorkflows.length)
		menu.push(menuWorkflows)

	const menuConfig = []
	if(api.auth.chk())
		menuConfig.push({ label:"Clienti", icon:faUsers, linkTo:"/config/customer/list" })
	if(api.auth.chk('acquisti'))
		menuConfig.push({ label:"Fornitori", icon:faTruck, linkTo:"/config/supplier/list" })
	if(menuConfig.length)
		menu.push(menuConfig)
	
	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}
