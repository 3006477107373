import React, { useState, useEffect, useContext } from 'react';
// import { useParams } from "react-router-dom"

import APICtx from '#api'
import Form, { Input, InputDate } from '#Form'
import Dialog from '#Dialog'
import { useEvalUser } from '#Display'

export const OfferDetailsDialog = ({ data, handleClose }) => (
	<Dialog
		handleClose={handleClose}
		open={data}
		title="Dettagli proposta"
		width="md"
	>
		{ Boolean(data) && <OfferDetailsForm data={data} /> }
	</Dialog>
)

const tsToDateTime = ts => {
	if(ts) {
		const date = new Date(ts).toISOString()
		return date.slice(0, 10).split('-').reverse().join('/') + ' ' + date.slice(11, 16)
	}
	else
		return ''
}

export const OfferDetailsForm = ({ data:supplier }) => {
	const [ data, setData ] = useState({})
	const api = useContext(APICtx)
	const evalUser = useEvalUser()

	const handleRefresh = async () => {
		const component = await api.call('workflow/component-req/get', { _id:supplier.componentRequest })
		setData({
			customer: component?.customer?.label,
			requestDate: component?.customerRequest?.date ? new Date(component.customerRequest.date).toISOString().slice(0, 10) : '',
			description: component?.description,
			quantity: component?.quantity,
			requestBrand: component?.brand,
			supplierWare: supplier.ware,
			supplierBrand: supplier.brand,
			supplierCost: supplier.cost,
			deliveryDate: supplier.deliveryDate,
			requestNotes: component?.notes,
			availabilityDate: tsToDateTime(supplier.availabilityUp?.ts),
			availabilityAuthor: supplier.availabilityUp?.ts ? await evalUser(supplier.availabilityUp?.author) : '',
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [ data._id ])

	const formDef = {
		callGet: data._id && "workflow/supplier/get",
		data,
	}

	return (
			<Form {...formDef}>
				<Input label="Cliente" name="customer" disabled />
				<InputDate label="Data richiesta" name="requestDate" disabled />
				<Input label="Descrizione" name="description" disabled />
				<Input label="Quantità" name="quantity" disabled />
				<Input label="Marca richiesta" name="requestBrand" disabled />
				<Input label="Articolo" name="supplierWare" disabled />
				<Input label="Marca" name="supplierBrand" disabled />
				<Input label="Costo" name="supplierCost" disabled />
				<Input label="Data consegna" name="deliveryDate" disabled />
				<Input label="Note" name="requestNotes" multiline disabled />
				<Input label="Passata in data e ora" name="availabilityDate" disabled />
				<Input label="Passata da" name="availabilityAuthor" disabled />
			</Form>
	)
}
