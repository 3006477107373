import React, { useState, useContext, useEffect } from 'react'
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

export default function CustomerEdit() {
	const api = useContext(APICtx)
	const { reqModuleId, _id } = useParams()
	const [ reqModule, setReqModule ] = useState({})

	useEffect(() => {
		reqModuleId && api.call('workflow/mod-impegno/get', { _id:reqModuleId }).then(ret => {
			console.log('WTF', ret)
			setReqModule(ret)
		})
	}, [ reqModuleId ])
	console.log('MODMOD', module)

	const formDef = {
		_id,
		callSet: _id ? "workflow/mod-impegno/row/update" : "workflow/mod-impegno/row/create",
		callGet: _id && "workflow/mod-impegno/row/get",
		extraData: { reqModuleId },
	}

	const title =
		(reqModule?.customer?.label ? reqModule.customer.label+' - ' : '') +
		(reqModule?.orderNumber ? reqModule.orderNumber+' - ' : '') +
		(_id ? 'Modifica' : 'Nuovo') + ' componente'
	const backTo = '/workflow/commitment-form/row/'+reqModuleId+'/list'
	return (
		<Tpl title={title} backTo={backTo}>
			<Form {...formDef}>
				<Input label="Descrizione" name="description" required />
				<Input label="Quantità B.A." name="quantity" />
				<Input label="Marca" name="brand" />
				<Input label="Quantità impegnata" name="committed" />
				<Input label="Prezzo" name="price" />
				<Input label="Sconto" name="discount" />
				<Input label="Data consegna" name="dateDelivery" />
			</Form>
		</Tpl>
	)
}
