import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputNumber, Select, FileUpload, Autocomplete } from '#Form'

const optionsPriority = [
	{ value:'2gg', label:'2gg' },
	{ value:'7gg', label:'7gg' },
	{ value:'low', label:'Oltre' },
]
const optionsType = [
	{ value:'standard', label:'Standard' },
	{ value:'new', label:'Nuovo' },
	{ value:'drawing', label:'Disegno' },
	{ value:'other', label:'Altro' },
]
const optionsBrandAlt = [
	{ value:'nazionale', label:'Nazionale' },
	{ value:'concorrenza', label:'Alternativa' },
]

const defaultData = {
	urgency: '7gg',
	type: 'standard',
}

const PREFIX_NEW = '[NUOVO] '

const evalBackTo = (_id, customerRequest) =>
	customerRequest ? '/workflow/component/customer-list/'+customerRequest : (
		_id ? '/workflow/component/list' : '/workflow/customer/list'
	)

export default function CustomerReqEdit() {
	const api = useContext(APICtx)
	const { _id, customerRequest } = useParams()
	const [ data, setData ] = useState({})
	const [ customerRequestData, setCustomerRequestData ] = useState({})

	const isNewCustomerReq = !customerRequest && !_id
	const aclRW = api.auth.chk('vendite') && !data?.isUnderEvaluation

	useEffect(() => {
		!_id && customerRequest && api.call('workflow/customer-req/get', { _id:customerRequest }).then(setCustomerRequestData)
	}, [ _id, customerRequest ])

	const formDef = {
		_id,
		callSet: aclRW && (isNewCustomerReq ? "workflow/customer-req/create" : (_id ? "workflow/component-req/update" : "workflow/component-req/create")),
		callGet: _id && "workflow/component-req/get",
		idRedirect: saveId => evalBackTo(_id, customerRequest || saveId),
		data,
		setData: (newData, { isNew }) =>
			setData(isNew ? defaultData : newData),
		extraData: { customerRequest },
	}

	const autocompleteCustomer = async searchString => {
		const newLabel = (searchString?.startsWith(PREFIX_NEW) ? '' : PREFIX_NEW) + searchString

		const skipList = searchString?.startsWith(PREFIX_NEW) || !searchString?.length || searchString.length < 3
		const options = skipList ? [] : await api.call('customer/options', { searchString })
		searchString?.length && !options.find(item => item.label===newLabel) && options.push({
			value: newLabel,
		})
		return options
	}

	const title = isNewCustomerReq ? 'Nuova richiesta cliente' : (
		(data?.customer?.label || customerRequestData?.customer?.label) + ' - ' + (_id ? 'Modifica richiesta componente' : 'Nuova richiesta componente')
	)
	return (
		<Tpl title={title} backTo={evalBackTo(_id, customerRequest)}>
			<Form {...formDef}>
				{ isNewCustomerReq && <Autocomplete label="Cliente" name="customer" required getOptions={autocompleteCustomer} /> }
				<Input label="Descrizione componente" name="description" required disabled={!aclRW} />
				<Input label="Quantità pezzi" name="quantity" required disabled={!aclRW} />
				<Select label="Priorità consegna" name="urgency" required options={optionsPriority} disabled={!aclRW} />
				<Select label="Rif. / Prodotto" name="type" required options={optionsType} disabled={!aclRW} />
				<Input label="Marca richiesta" name="brand" required disabled={!aclRW} />
				<Select label="Marca alternativa" name="brandAlt" options={optionsBrandAlt} emptyLabel="-- Nessuna --" disabled={!aclRW} />
				<Input label="Prezzo vendita obiettivo" name="desiredPrice" disabled={!aclRW} />
				<FileUpload label="Disegno allegato" name="attachment" tokenEndpoint="workflow/component-req/get-upload-token" disabled={!aclRW} />
				<Input label="Note" name="notes" multiline disabled={!aclRW} />
			</Form>
		</Tpl>
	)
}
