import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Search, { SearchText } from '#Search'
import Fab from '#Fab'

import { faPlus, faTrash, faEdit, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'

const tableDef = [
	{ label:"Codice AS400", field:'code' },
	{ label:"Denominazione", field:'label' },
	{ label:"Località", content:row => row?.address?.locality },
	{ label:"CAP", content:row => row?.address?.zip },
	{ label:"Provincia", content:row => row?.address?.province },
	{ label:"Telefono", content:row => row?.phone },
]

export default function CustomerList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')

	const handleDelete = ({ _id, label }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il cliente "' + label + '"?',
			onConfirm: () => api.call('customer/remove', { _id }).then(handleRefresh),
		})
	const handleRefresh = () => api.call('customer/list', { searchString }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ searchString ])

	const getRow = _id => api.call('customer/get', { _id })

	const defBtns = api.auth.chk('superadmin') ? [
		{
			icon: faMagnifyingGlass,
			label: 'Visualizza',
			linkTo: row => '/config/customer/edit/' + row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	] : []

	return (
		<Tpl title="Archivio clienti">
			<Search>
				<SearchText label="Cerca codice / nominativo / indirizzo" name="src-string" onChange={setSearchString} />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={50} />
		</Tpl>
	)
}
