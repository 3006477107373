import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Search, { SearchText, SearchSelect } from '#Search'
import { evalDate } from '#Display'
import { numberToCode } from './reqHelpers'

import { faPlus, faCabinetFiling, faEdit, faTags, faFileDownload, faMagnifyingGlass, faHourglass, faCheck, faTruckFast, faRotateBack, faPhone, faSlash } from '@fortawesome/pro-regular-svg-icons'

const statusTypes = [
	{ value:'all', label:'Tutti' },
	{ value:'waiting-offers', label:'In attesa di offerte' },
	{ value:'waiting-accept', label:'In attesa di accettazione' },
	{ value:'completed', label:'Ordinati' },
	{ value:'archived', label:'Archiviati' },
]

const useTableDef = () => {
	const api = useContext(APICtx)
	const { customerRequest } = useParams()
	
	const def = []
	if(!customerRequest)
		def.push(
			{ label:"Cliente", content:row => row.customer?.label },
			{ label:"Num. richiesta", content:row => numberToCode(row.customerRequest?.number) },
			{ label:"Data richiesta", content:row => row.customerRequest?.date, type:'date' },
		)
	def.push(
		{ label:"Quantità", field:'quantity' },
		{ label:"Descrizione", field:'description' },
		{ label:"Marca", field:'brand' },
		{ label:"Proposte fornitori", cellAlign:'center', content:row => (
			<Box sx={{ color:(row.suppliersCounters?.all<1 ? 'red' : (row.suppliersCounters?.available>0 ? 'green' : 'orange')) }}>
				{api.auth.chk('acquisti') ? (row.suppliersCounters?.all || 0) : ''} <FontAwesomeIcon icon={row.isSupplierAccepted ? faCheck : faHourglass} />
			</Box>
		), tooltip:row =>
			row.suppliersCounters?.all<1 ?
				'In attesa elaborazione da rep. acquisti' : (
					row.suppliersCounters?.available>0 ? (
						row.isSupplierAccepted ? 'Fornitore selezionato' : 'Fornitori disponibili'
					) : 'In attesa passaggio risposte fornitori'
				)
		},
		{ content:row => row.urgency==='2gg' ? faTruckFast : undefined, type:'icon', iconColor:'red', tooltip:'Urgenza 2gg' },
		{ content:row => row._deleted ? faCabinetFiling : undefined, type:'icon', tooltip:'Archiviata' },
	)
	
	return def
}

export default function ComponentReqList() {
	const api = useContext(APICtx)
	const { customerRequest } = useParams()
	const [ ids, setIds ] = useState([])
	const [ searchString, setSearchString ] = useState('')
	const [ searchCustomer, setSearchCustomer ] = useState('')
	const [ searchStatus, setSearchStatus ] = useState(null)
	const [ customer, setCustomer ] = useState(null)
	const tableDef = useTableDef()
	const inFullList =  !customerRequest

	const handleDelete = ({ _id, description }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il componente "' + description + '"?',
			onConfirm: () => api.call('workflow/component-req/remove', { _id }).then(handleRefresh),
		})
	}
	const handleUnderEvaluationToggle = ({ _id, isUnderEvaluation }) =>
		api.call('workflow/component-req/set-under-evaluation', { _id, isUnderEvaluation:!isUnderEvaluation })
			.then(handleRefresh)
	const handleRefresh = () => api.call('workflow/component-req/list', { searchString, searchCustomer, searchStatus, customerRequest }).then(setIds)
	useEffect(() => {
		(customerRequest || searchStatus!==null) && handleRefresh()
	}, [ searchString, searchCustomer, searchStatus, customerRequest ])
	useEffect(() => {
		customerRequest ? api.call('workflow/customer-req/get', { _id:customerRequest }).then(setCustomer) : setCustomer(null)
	}, [ customerRequest ])

	const getRow = _id => api.call('workflow/component-req/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: row => row.isUnderEvaluation ? 'Visualizza' : 'Modifica',
			linkTo: row => customerRequest ?
				'/workflow/component/customer-edit/'+customerRequest+'/'+row._id :
				'/workflow/component/edit/'+row._id
		},
		{
			icon: faMagnifyingGlass,
			label: 'Visualizza allegato',
			onClick: row => api.storage.download(row.attachmentJWT, true),
			show: row => Boolean(row.attachmentJWT),
		},
		{
			icon: faFileDownload,
			label: 'Scarica allegato',
			onClick: row => api.storage.download(row.attachmentJWT, false),
			show: row => Boolean(row.attachmentJWT),
		},
		{
			show: row => !row.suppliersCounters?.all && api.auth.chk('acquisti'),
			icon: faPhone,
			icon2: row => row.isUnderEvaluation && faSlash,
			label: row => (!row.isUnderEvaluation ? 'Comincia' : 'Annulla') + ' valutazione fornitori',
			onClick: handleUnderEvaluationToggle,
		},
		{
			show: row => Boolean(row.isUnderEvaluation),
			icon: faTags,
			label: 'Prezzi fornitori',
			linkTo: row => customerRequest ?
				'/workflow/supplier/customer-list/' + customerRequest + '/' + row._id :
				'/workflow/supplier/component-list/' + row._id
		},
		{
			show: row => !row._deleted && api.auth.chk('vendite'),
			icon: faCabinetFiling,
			label: 'Archivia',
			onClick: handleDelete,
		},
	]

	const title = customer ? customer.customer.label+' - '+evalDate(customer._created) : 'Richieste componenti'
	const backTo = customerRequest ? "/workflow/customer/list" : undefined
	const defaultStatus = api.auth.chk('acquisti') ? 'waiting-offers' : 'waiting-accept'
	return (
		<Tpl title={title} backTo={backTo}>
			<Search>
				<SearchText label="Cerca descrizione" name="src-string" onChange={setSearchString} />
				<SearchText label="Cerca cliente" name="src-customer" onChange={setSearchCustomer} />
				{ inFullList && <SearchSelect name="src-status" onChange={setSearchStatus} label="Stato" options={statusTypes} defaultValue={defaultStatus} /> }
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={50} />
			{ Boolean(customerRequest) && <Fab
				label="Crea una richiesta componente"
				icon={faPlus}
				linkTo={'/workflow/component/customer-edit/'+customerRequest}
			/> }
		</Tpl>
	)
}
