const digitsSize = 3
const lowestLetter = Math.pow(10, digitsSize)

export const thousandsToCode = numberInput => {
	let num = numberInput
	let result = ""
	while(num > 0) {
		num--
		const remainder = num % 26
		result = String.fromCharCode(65 + remainder) + result
		num = Math.floor(num / 26)
	}
	return result
}

export const numberToCode = number => {
	if(!number)
		return ''
	const lower = (number % lowestLetter).toString()
	const higher = Math.floor(number / lowestLetter)
	return higher ? thousandsToCode(higher) + lower.padStart(1, '0') : lower
}

export const codeToNumber = code => {
	if(!code.match(/^[a-zA-Z]*[0-9]+$/))
		return 0
	const lower = parseInt(code.slice(-digitsSize)) || 0

	const letters = code.slice(0, -digitsSize).toUpperCase()
	let higher = 0
	for (let i = 0; i < letters.length; i++) {
		const charLetter = letters.charCodeAt(i)
		const digitValue = charLetter - 65 + 1
		higher = higher * 26 + digitValue
	}

	return (higher || 0)*lowestLetter + lower
}
