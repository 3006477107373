import React from 'react'
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

export default function SupplierEdit() {
	const { _id } = useParams()

	const formDef = {
		_id,
		// callSet: _id ? "supplier/update" : "supplier/create",
		callGet: _id && "supplier/get",
	}

	const title = 'Visualizza fornitore'
	const backTo = '/config/supplier/list'
	return (
		<Tpl title={title} backTo={backTo}>
			<Form {...formDef}>
				<Input label="Denominazione" name="label" required disabled />
				<Input label="Codice AS400" name="code" disabled />
				<Input label="Località" name="address.locality" disabled />
				<Input label="Provincia" name="address.province" disabled />
			</Form>
		</Tpl>
	)
}
