import React, { useState, useEffect, useContext } from 'react';

import APICtx from '#api'
import Form, { Autocomplete, Input, Checkbox } from '#Form'
import { Typography } from '@mui/material';

import Dialog from '#Dialog'

export const ModImpegnoDialog = ({ data, closeEdit, handleRefresh, checkboxes }) => {
	const api = useContext(APICtx)
	const [ customer, setCustomer ] = useState(false)
	
	const handleEditStatusClose = () => {
		closeEdit()
		setCustomer(false)
		return handleRefresh?.()
	}

	const evalCustomers = async () => {
		const customers = checkboxes && await Promise.all(
			checkboxes.map(async supplierId => {
				const supplierReq = await api.call('workflow/supplier/get', { _id:supplierId })
				const componentReq = supplierReq?.componentRequest ? await api.call('workflow/component-req/get', { _id:supplierReq.componentRequest }) : null
				return componentReq?.customer?.label && componentReq?.customer?._id ? {
					label: componentReq?.customer?.label,
					value: componentReq?.customer?._id,
				} : null
			})
		)
		setCustomer(
			(customers && customers
				.filter(Boolean)
				.reduce(
					(acc, cur) => acc===false || (acc?.label===cur?.label && acc?.value===cur?.value) ? cur : null,
					false
				)
			)
			|| null
		)
	}
	useEffect(() => {	
		data && evalCustomers()
	}, [ data, checkboxes ])

	const title = Boolean(data) ? "Modulo d'impegno" : "Crea nuovo modulo d'impegno"
	return (
		<Dialog
			handleClose={handleEditStatusClose}
			open={data}
			title={title}
			width="md"
		>
			{ Boolean(data) && customer!==false && <ModImpegnoForm _id={data?._id} suppliers={checkboxes} defaultCustomer={customer} /> }
		</Dialog>
	)
}

export function ModImpegnoForm({ _id, suppliers, defaultCustomer }) {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const aclRW = (
		!data.isConfirmedRequest && api.auth.chk('vendite') ||
		// (data.isConfirmedRequest && api.auth.chk('customer-service')
		api.auth.chk('customer-service')
	)

	const autocompleteCustomer = async searchString => {
		const skipList = !searchString?.length || searchString.length < 3
		const options = skipList ? [] : await api.call('customer/options', { searchString, hideTemporary:true })
		return options
	}

	const formDef = {
		_id,
		callSet: aclRW && (_id ? 'workflow/mod-impegno/update' : 'workflow/mod-impegno/create'),
		submitLabel: _id ? "Salva" : "Crea",
		callGet: _id && "workflow/mod-impegno/get",
		extraData: { suppliers },
		data,
		setData: (newData, { isNew }) =>
			setData(isNew ? { customer:defaultCustomer } : newData),
		idRedirect: saveId => '/workflow/commitment-form/row/' + saveId + '/list',
	}

	return (
		<Form {...formDef}>
			<Autocomplete label="Cliente" name="customer" required getOptions={autocompleteCustomer} disabled={!aclRW} />
			<Input label="Numero ordine" name="orderNumber" required disabled={!aclRW} />
			<Input label="Destinazione" name="destination" disabled={!aclRW} />
			<Checkbox label="Ordine a programma" name="orderPlanned" disabled={!aclRW} />
			<Checkbox label="Richiesta materiale all'uff. acquisti" name="reqToProcurement" disabled={!aclRW} />
			<Input label="Note" name="notes" multiline disabled={!aclRW} />
			<Typography>Trasporto</Typography>
			<Checkbox label="Destinatario" name="tranportDest" disabled={!aclRW} />
			<Checkbox label="Corriere" name="tranportCorr" disabled={!aclRW} />
			<Checkbox label="Porto assegnato" name="tranportPAss" disabled={!aclRW} />
			<Checkbox label="Mittente" name="tranportMitt" disabled={!aclRW} />
			<Checkbox label="Porto franco" name="tranportPFr" disabled={!aclRW} />
			<Checkbox label="Porto franco con addebito in fattura" name="tranportPFrFatt" disabled={!aclRW} />
			<Input label="Data richiesta" name="dateRequested" disabled={!aclRW} />
		</Form>
	)
}
