import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Search, { SearchText, SearchSelect } from '#Search'
import { evalDate } from '#Display'
import { numberToCode, codeToNumber } from './reqHelpers'

import { faPlus, faListCheck, faCabinetFiling } from '@fortawesome/pro-regular-svg-icons'

const statusTypes = [
	{ value:'all', label:'Tutti' },
	{ value:'active', label:'Attivi' },
	{ value:'archived', label:'Archiviati' },
]

const Components = ({ customerRequest }) => {
	const api = useContext(APICtx)
	const [ components, setComponents ] = useState([])

	useEffect(() => {
		api.call('workflow/component-req/list', { customerRequest }).then(setComponents)
	}, [ customerRequest ])

	return components.length
}

const useTableDef = () => {
	return [
		{ label:"Numero", content:row => numberToCode(row?._registry?.number) },
		{ label:"Data", field:'_created', type:'date' },
		{ label:"Cliente", content:row => row.customer?.label },
		{ label:"Componenti", content:row => <Components customerRequest={row._id} /> },
		{ content:row => row._deleted ? faCabinetFiling : undefined, type:'icon', tooltip:'Archiviata' },
	]
}

export default function CustomerReqList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ searchNumber, setSearchNumber ] = useState('')
	const [ searchString, setSearchString ] = useState('')
	const [ searchStatus, setSearchStatus ] = useState(null)
	const tableDef = useTableDef()

	const handleDelete = ({ _id, _created }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler archiviare la richiesta del ' + evalDate(_created) + '?',
			onConfirm: () => api.call('workflow/customer-req/remove', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('workflow/customer-req/list', { searchNumber:codeToNumber(searchNumber), searchString, searchStatus }).then(setIds)
	useEffect(() => {
		searchStatus!==null && handleRefresh()
	}, [ searchNumber, searchString, searchStatus ])

	const getRow = _id => api.call('workflow/customer-req/get', { _id })
	const defBtns = [
		{
			icon: faListCheck,
			label: 'Componenti richiesti',
			linkTo: row => '/workflow/component/customer-list/' + row._id,
		},
		{
			show: row => !row._deleted,
			icon: faCabinetFiling,
			label: 'Archivia',
			onClick: handleDelete,
		},
	]

	return (
		<Tpl title="Richieste cliente">
			<Search>
				<SearchText label="Cerca numero" name="src-number" onChange={setSearchNumber} />
				<SearchText label="Cerca cliente" name="src-string" onChange={setSearchString} />
				<SearchSelect name="src-status" onChange={setSearchStatus} label="Stato" options={statusTypes} defaultValue="active" />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={50} />
			<Fab label="Crea una richiesta" icon={faPlus} linkTo="/workflow/customer/create" />
		</Tpl>
	)
}
